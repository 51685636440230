import Vue from "vue";

/*TITLE*/
document.title = "Puerta de las Rozas | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Puerta de las Rozas";
Vue.prototype.$subtitle = "Abre la puerta a tu nueva vida";

/*INTRO*/
Vue.prototype.$promoter = "Puerta de las Rozas";
Vue.prototype.$introSubtitle = "Abre la puerta a tu nueva vida";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-ac4-home-1l-salzburg-193x1383--20230126120131.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "banyo-1-vela-natural-596x596-vela-natural-316x90-deco-bremen-roble-natural-316x90-mampara-yove-5--20230126120153.jpg";
Vue.prototype.$image_bath2 = "banyo-2-rodano-taupe-termostatica-smart--20230126120116.jpg";
Vue.prototype.$image_room = "ac4-home-1l-kaprun-193x1383--20230126120151.jpg";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-ac4-home-1l-salzburg-193x1383--20230126120131.jpg",
  },
  {
    src: "salon-ac4-home-1l-kaprun-193x1383--20230126120141.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo-1-vela-natural-596x596-vela-natural-316x90-deco-bremen-roble-natural-316x90-mampara-yove-5--20230126120153.jpg",
  },
  {
    src: "banyo-1-bottega-caliza-spiga-bottega-caliza--20230126120102.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-2-rodano-taupe-termostatica-smart--20230126120116.jpg",
  },
  {
    src: "banyo-2-dover-acero--20230126120134.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
  {
    src: "ac4-home-1l-kaprun-193x1383--20230126120151.jpg",
  },
  {
    src: "ac4-home-1l-salzburg-193x1383--20230126120100.jpg",
  },
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20cañadilla%20las%20rozas%20madrid%2028231",
    text: "C/ Cañadilla, Las Rozas - Madrid 28231",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=edificio%20lagos%20park%20camino%20real%202%20bis%2028230%20las%20rozas%20de%20madrid%20madrid",
    text: "Edificio Lagos Park. Camino Real, 2 bis, 28230 Las Rozas de Madrid, Madrid",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:636500999",
    text: "636500999",
  },
  {
    icon: "mdi-email",
    link: "mailto:puertadelasrozas@ikasa.es",
    text: "puertadelasrozas@ikasa.es",
  },
];
